import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import { useLocation } from "react-router-dom";
import {
    adminPath,
    isManuallyDisablingAllExams,
    isManuallyDisablingAllVideos,
    isManullayDisablingNotifications,
    isWinterComing,
    tickerNewsLineEnabled,
} from "../../../services/defaultSettings";
import CenterIcon from "../../../components/ui/CenterIcon";
import { description as formatDescription } from "../../../utils/ar";
import localStorageService from "../../../services/localStorageService";
import bg from "../../../assets/bg.png";
import { ReactComponent as SnowTopFrame } from "../../../assets/Updated-imagery/snowTopFrame.svg";

const GlobalNotifications = ({ notifications = [] }) => {
    const { token, winners } = useContext(AuthContext);
    const [isHidden, setIsHidden] = useState(false);
    const [className, setClassName] = useState("");
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes(adminPath)) {
            setClassName(" !hidden");
        } else {
            setClassName("");
        }
    }, [location.pathname]);
    return (
        <>
            {notifications.length > 0 && token ? (
                <div
                    className={`absolute w-full ${
                        !winners && tickerNewsLineEnabled ? "top-[5rem]" : "top-[6.5rem]"
                    } right-0 left-0 px-2 py-2 ${className} ${isHidden ? "!hidden" : ""}`}
                >
                    <div className="relative rounded-md bg-[#FB4040]/90 dark:bg-rose-600/90 smooth text-white p-5 text-center">
                        {isWinterComing && (
                            <SnowTopFrame className="absolute brightness-90 max-w-lg -top-7 z-50 left-0 right-0 mx-auto " />
                        )}
                        <div className="flex-center-both flex-col space-y-2">
                            <div className="font-w-bold font-h2 underline">
                                {notifications[0].name}
                            </div>
                            <div className="font-h3">{notifications[0].description}</div>
                        </div>
                        <div className="absolute inset-0 w-full h-full">
                            <div
                                className="w-auto h-full md:w-full opacity-40 relative mr-auto transform "
                                style={{
                                    backgroundImage: "url(" + bg + ")",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center top",
                                }}
                            ></div>
                        </div>
                        <button
                            className="absolute left-2 top-2 pt-0.5 flex-center-both rounded-md  bg-violet-500 text-black font-h1 hover-shadow smooth"
                            onClick={() => setIsHidden(true)}
                        >
                            <CenterIcon icon="uil:x" />
                        </button>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default GlobalNotifications;
