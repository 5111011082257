import React, { useEffect, useState } from "react";
import InputField from "../../components/form/elements/InputField";
import InputIcon from "../../components/form/elements/InputIcon";
import http from "../../services/httpServices";
import auth from "../../services/authServices";
import modal from "../../services/modalServices";
import AdminContainer from "../../components/ui/AdminContainer";
import {
    handleFormErrors,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import AdminForm from "../../components/ui/AdminForm";
import { useNavigate } from "react-router-dom";

const CouponCodeTable = ({ data }) => {
    const couponExamples = [
        {
            prefix: "DISCOUNT-",
            description: "خصم",
            example: "DISCOUNT-ABC123-SN-000001",
        },
        {
            prefix: "PRIZE-",
            description: "هدايا",
            example: "PRIZE-DEF456-SN-000002",
        },
        {
            prefix: "KING-",
            description: "باسم الكينج",
            example: "KING-GHI789-SN-000003",
        },
    ];

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full border border-gray-300 dark:border-gray-600 text-center">
                <thead>
                    <tr className="bg-gray-100 dark:bg-gray-700 smooth">
                        <th className="px-4 py-2  bg-green-300 dark:bg-green-800 smooth clr-text-primary  border border-gray-300 dark:border-gray-600 ">
                            {" "}
                            وصف الكوبون
                        </th>
                        <th className="px-4 py-2 clr-text-primary smooth border border-gray-300 dark:border-gray-600">
                            نوع الكوبون
                        </th>
                        <th className="px-4 py-2 clr-text-primary smooth border border-gray-300 dark:border-gray-600">
                            مثال للكوبون المستخدم
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {couponExamples.map((item, index) => (
                        <tr
                            key={index}
                            className={`hover:bg-gray-50 ${
                                data.prefix_name === item.prefix
                                    ? "bg-pink-200 dark:bg-pink-800 smooth"
                                    : ""
                            }`}
                        >
                            <td className="px-4 py-2 clr-text-primary bg-slate-100 dark:bg-slate-900 smooth border border-gray-300 dark:border-gray-600">
                                {item.description}
                            </td>
                            <td className="px-4 py-2 clr-text-primary smooth border border-gray-300 dark:border-gray-600">
                                {item.prefix}
                            </td>
                            <td className="px-4 py-2 clr-text-primary smooth border border-gray-300 dark:border-gray-600">
                                {item.example}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const initialState = {
    name: "",
    quantity: 0,
    insert_auto_type: "coupons",

    prefix_name: "DISCOUNT-",
    discount: 0,
    maximum: 0,
};
const CouponInsertAuto = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {}, [data.prefix_name]);

    let inputFields = [
        {
            id: "name",
            placeholder: "العنوان",
            icon: <InputIcon icon="fluent:app-title-24-filled" />,
        },
        {
            id: "prefix_name",
            placeholder: "العنوان",
            type: "select",
            options: [
                { value: "DISCOUNT-", text: "خصم" },
                { value: "PRIZE-", text: "هدايا" },
                { value: "KING-", text: "باسم الكينج" },
            ],
            icon: <InputIcon icon="fluent:app-title-24-filled" />,
        },
        {
            element: "html",
            input: <CouponCodeTable data={data} />,
        },
        {
            id: "quantity",
            placeholder: "عدد الكوبونات",
            type: "number",
            icon: <InputIcon icon="fluent:document-page-number-24-regular" />,
        },
        {
            id: "discount",
            placeholder: "الخصم (نسبة مئوية ٪)",
            type: "number",
        },
        {
            id: "maximum",
            placeholder: "اقصى مبلغ للخصم (بـ الجنيه)",
            type: "number",
        },
    ];

    const navigate = useNavigate();
    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            couponInsertAuto();
        });
    };
    const couponInsertAuto = async () => {
        try {
            const adminToken = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(adminToken);

            const toSendData = { ...data };

            const { data: response } = await http.post(
                "/api/coupon/insert_auto",
                toSendData,
                config
            );

            modal.message({
                title: "تم تنفيذ طلبك بنجاح",
                text: response.message,
                buttons: { cancel: "متابعة", save: "حفظ و متابعة", confirm: "مشاهدة الجدول" },
                callback: (e) => {
                    if (e && e !== "save") {
                        navigate("../insert_autos");
                    } else if (e === "save") {
                    } else {
                        setData(initialState);
                    }
                },
            });
            setIsLoading(false);
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };

    return (
        <div className="space-y-10">
            <AdminContainer>
                <AdminForm onSubmit={handleSubmit} isLoading={isLoading} buttonText="انشاء">
                    {inputFields.map((input, key) =>
                        renderInputFields(
                            key,
                            input.handleChange ? input.handleChange : handleChange,
                            data,
                            setData,
                            errors,
                            input
                        )
                    )}
                </AdminForm>
            </AdminContainer>
        </div>
    );
};

export default CouponInsertAuto;
